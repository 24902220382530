import { createMultiStyleConfigHelpers, extendTheme } from "@chakra-ui/react";
import { radioAnatomy } from "@chakra-ui/anatomy";
import "@fontsource-variable/outfit";

// For custom radio varaint
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const cardStyle = definePartsStyle({
  control: {
    display: "none",
  },
  label: {
    border: "1px solid #E1E1E1",
    borderRadius: "0.625rem",
    px: 3,
    py: 2,
    ml: 0,
    _checked: {
      border: "1px solid #013768",
      backgroundColor: "#013768",
      color: "white",
      fontWeight: 600,
    },
  },
});

const radioTheme = defineMultiStyleConfig({
  variants: {
    "card-variant": cardStyle,
  },
});

// For custom font
const theme = extendTheme({
  fonts: {
    heading: `'Outfit Variable', sans-serif`,
    body: `'Outfit Variable', sans-serif`,
  },
  components: {
    Radio: radioTheme,
  },
});

export default theme;
