import "./App.css";
import CourseRenewalForm, { ExistingCourse } from "./CourseRenewalForm";

import { Box, Center, ChakraProvider, Spinner, Text } from "@chakra-ui/react";
import theme from "./theme";
import FormHeader from "./components/FormHeader";
import SuccessPage from "./SuccessPage";
import { useEffect, useState } from "react";
import _, { set } from "lodash";
import { ApiService } from "./lib/api";
import camelcaseKeys from "camelcase-keys";

const levels = [
  "N1",
  "N2",
  "K1",
  "K2",
  "P1",
  "P2",
  "P3",
  "P4",
  "P5",
  "P6",
  "S1",
  "S2",
  "S3",
  "OL",
];

// Sorted in alphabetical order after names are replaced with public names
const centres = [
  "Balmoral",
  "Bishan",
  "Bukit Batok Central",
  "Bukit Panjang",
  "Choa Chu Kang",
  "West Campus",
  "Compassvale",
  "Hougang",
  "Bishan J8",
  "Jurong West",
  "Katong",
  "Lequest",
  "Northshore",
  "Novena",
  "One Punggol",
  "Mind Stretcher Education",
  "Punggol",
  "Punggol Central",
  "Rivervale",
  "Seletar",
  "Sembawang (Main)",
  "Simei",
  "East Campus",
  "Central Campus",
  "Woodlands Central",
  "Woodleigh",
  "Yishun",
];

export const centreInternalToPublicNames: { [key: string]: string } = {
  "Bishan J8": "Junction 8",
  "Central Campus": "Toa Payoh",
  "East Campus": "Tampines",
  "Mind Stretcher Education": "Online",
  "Sembawang (Main)": "Sembawang",
  "West Campus": "Clementi",
  Compassvale: "Compassvale (NEW)",
};

const courseNames = [
  "A* Writing Lab",
  "Additional Mathematics (Express)",
  "Budding Writers",
  "Chinese (Express) 快捷华文",
  "Chinese 4Cs 华文4Cs",
  "English",
  "English (Achiever)",
  "English (G2)",
  "English (GEP/High Achiever)",
  "English (High Achiever)",
  "English And Language Arts (G3)",
  "GEP Prep Lab (English & Mathematics)",
  "GEP Prep Lab (English)",
  "GEP Prep Lab (Mathematics)",
  "Higher Chinese 4Cs 高级华文4Cs",
  "Higher Chinese 高级华文",
  "Integrated Chinese 综合华文",
  "Mathematics",
  "Mathematics (Achiever)",
  "Mathematics (G2)",
  "Mathematics (G3)",
  "Mathematics (GEP/High Achiever)",
  "Mathematics (High Achiever)",
  "P1 English and Mathematics Preparatory",
  "PSLE A* Writers",
  "Science",
  "Science (Achiever)",
  "Science (Combined Sci - Chemistry, Physics)",
  "Science (G2)",
  "Science (G3)",
  "Science (GEP/High Achiever)",
  "Science (Pure Chemistry)",
  "Science (Pure Physics)",
];

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const weekdayTimeSlots = [
  "3:00 PM",
  "4:00 PM",
  "5.15 PM",
  "6.30 PM",
  "7.30 PM",
];
const weekendTimeslots = [
  "9:00 AM",
  "11:15 AM",
  "2:00 PM",
  "4:15 PM",
  "7:00 PM",
];

function App() {
  const [existingCourseCodes, setExistingCourseCodes] = useState<
    ExistingCourse[]
  >([]);
  const [formUniqueCodeState, setFormUniqueCode] = useState<string>("");
  const [studentName, setStudentName] = useState<string>("");

  useEffect(() => {
    async function loadPrefillData() {
      if (window.location.pathname === "/timeslot-preference/success") {
        return;
      }

      try {
        const formUniqueCode = _.last(window.location.pathname.split("/")); // Always take the last part of the URL
        setFormUniqueCode(formUniqueCode || "");

        const { data } = await ApiService.get(
          `/form-prefills/timeslot-preference/${formUniqueCode}/`
        );
        const camelCasedData = camelcaseKeys(data, { deep: true });

        setExistingCourseCodes(camelCasedData.prefillData.existingCourses);
        setStudentName(camelCasedData.prefillData.studentName);
      } catch (e) {
        // TODO: maybe log error to somewhere
        console.log("Failed to load prefill data", e);
      }
    }

    loadPrefillData();
  }, []);

  // If data hasn't loaded yet, show loading screen
  if (
    _.isEmpty(existingCourseCodes) &&
    window.location.pathname !== "/timeslot-preference/success"
  ) {
    return (
      <ChakraProvider theme={theme}>
        <FormHeader />
        <Center display="flex" flexDirection="column" gap={20} p={8}>
          <Spinner size="xl" />
          <Text maxWidth="600px" textAlign="center" fontSize="18px">
            We're fetching your details now. If the form takes too long to load,
            please check that the link was copied correctly or contact us via
            Whatsapp.
          </Text>
        </Center>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Box className="App">
        <FormHeader />
        {window.location.pathname === "/timeslot-preference/success" ? (
          <SuccessPage />
        ) : (
          <CourseRenewalForm
            levels={levels}
            existingCourses={existingCourseCodes}
            centres={centres}
            courseNames={courseNames}
            days={days}
            weekdayTimeslots={weekdayTimeSlots}
            weekendTimeslots={weekendTimeslots}
            formUniqueCode={formUniqueCodeState}
            studentName={studentName}
          />
        )}
      </Box>
    </ChakraProvider>
  );
}

export default App;
