import _ from "lodash";

import { useForm, useFieldArray } from "react-hook-form";
import FinalisedCourseCard from "./components/FinalisedCourseCard";
import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowDownIcon, AddIcon } from "@chakra-ui/icons";
import { centreInternalToPublicNames } from "./App";

type Course = {
  level: string;
  centre: string;
  courseName: string;
  day: string;
  timeslot: string;
};

export type ExistingCourse = {
  ay24: Course & { courseCode: string }; // AY25 has no course code because it's not in SalesForce yet
  ay25: Course;
  isRealTimeslot: boolean;
  renew: string;
  renewReason: string;
};

type CourseRenewalFormProps = {
  existingCourses: ExistingCourse[];
  newCourses: Course[];
  formUniqueCode: string;
};

const courseSchema = z.object({
  level: z.string().nonempty(),
  centre: z.string().nonempty(),
  courseName: z.string().nonempty(),
  day: z.string().nonempty(),
  timeslot: z.string().nonempty(),
});

const existingCourseSchema = z
  .object({
    ay24: courseSchema,
    ay25: courseSchema,
    isRealTimeslot: z.boolean(),
    renew: z.enum(["true", "false"], {
      message: "Please let us know if this time slot works for you.",
    }),
    renewReason: z
      .string({
        message: "Please select one of the reasons below.",
      })
      .optional(),
  })
  .refine((input) => {
    // Only allow renewReason to be undefined if renew is not "false"
    if (input.renew === "false" && input.renewReason === undefined) {
      return false;
    }
    return true;
  });

const newCourseSchema = z.object({
  level: z.string().min(1, { message: "Please select a level." }),
  centre: z.string().min(1, { message: "Please select a level." }),
  courseName: z.string().min(1, { message: "Please select a course." }),
  day: z.string().min(1, { message: "Please select a day." }),
  timeslot: z.string().min(1, { message: "Please select a time." }),
});

const schema = z.object({
  existingCourses: z.array(existingCourseSchema),
  newCourses: z.array(newCourseSchema),
  formUniqueCode: z.string(),
});

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/v1";

const CourseRenewalForm = ({
  levels,
  existingCourses,
  centres,
  courseNames,
  days,
  weekdayTimeslots,
  weekendTimeslots,
  formUniqueCode,
  studentName,
}: {
  levels: string[];
  existingCourses: ExistingCourse[];
  centres: string[];
  courseNames: string[];
  days: string[];
  weekdayTimeslots: string[];
  weekendTimeslots: string[];
  formUniqueCode: string;
  studentName: string;
}) => {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    resetField,
  } = useForm<CourseRenewalFormProps>({
    defaultValues: {
      existingCourses: existingCourses.map((course) => ({
        ...course,
      })),
      newCourses: [],
      formUniqueCode,
    },
    resolver: zodResolver(schema),
  });

  console.log("errors", errors);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "newCourses",
  });

  const onSubmit = async (data: CourseRenewalFormProps) => {
    // Handle form submission here
    await fetch(`${API_BASE_URL}/form-submissions/timeslot-preference`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        response: data,
      }),
    });
    try {
    } catch (err) {
      console.error("There was an error submitting the form", err);
    }

    window.location.href = "/timeslot-preference/success";
  };

  return (
    <VStack backgroundColor="#F7F9FF" alignItems="center" pb={20}>
      <VStack maxW="600px" alignItems="center" px={6}>
        <VStack alignItems={"flex-start"}>
          <Heading
            fontSize="28"
            fontWeight="700"
            color="#013768"
            lineHeight="120%"
            textAlign={"left"}
            pt={10}
          >
            AY2025 Time Slot Preference Survey
          </Heading>
          {!_.isEmpty(studentName) && (
            <Text fontSize={18} fontWeight={500} mt={4}>
              Student Name: {studentName}
            </Text>
          )}
          <Text fontSize={16} lineHeight="140%" textAlign={"left"} mt={7}>
            Dear parents,
            <br />
            <br />
            Thank you for trusting us with your child’s education journey in
            2024. <br />
            <br />
            We are now planning our timetable for the 2025 academic year. To
            help serve you better, please fill out this short survey on what
            class timings work best for you. We have kept to your current
            timings where possible and would appreciate your quick confirmation.
            <br />
            <br />
            We will finalise the class timetables and reach out again when the
            2025 Priority Registration opens in September. Thank you!
          </Text>
          <Divider mt={7} mb={10} />
        </VStack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack gap={8} textAlign={"left"} alignItems={"flex-start"}>
            <VStack gap={4} alignItems={"flex-start"}>
              <Box
                px={3}
                py={1}
                backgroundColor="#A76EF9"
                color="white"
                borderRadius="3xl"
              >
                <Text fontSize="14px">
                  Estimated time for completion: 3 mins
                </Text>
              </Box>
              <Text fontSize="18px" fontWeight={500} lineHeight="130%">
                Current enrolments & what your child will be promoted to in 2025
              </Text>
            </VStack>
            {existingCourses.map((course, index) => {
              // using watch as a hack to trigger re-render
              const watchRenew = watch(`existingCourses.${index}.renew`);
              const renewError = _.get(
                errors,
                `existingCourses[${index}].renew`
              );
              const renewReasonError = _.get(
                errors,
                `existingCourses[${index}].renewReason`
              );

              // Promotion heuristic for entries without AY25 detail
              let ay25Level;
              if (course.ay24.level === "OL")
                return; // skip timeslot asking if ay24 is already OL
              else {
                const ay24LevelIndex = _.findIndex(
                  levels,
                  (level) => level === course.ay24.level
                );
                console.log(
                  "ay24LevelIndex",
                  course.ay24.level,
                  ay24LevelIndex,
                  levels[ay24LevelIndex + 1]
                );
                ay25Level = levels[ay24LevelIndex + 1];
              }

              if (!course.ay25) {
                course.ay25 = {
                  level: ay25Level,
                  centre: course.ay24.centre,
                  courseName: course.ay24.courseName,
                  day: course.ay24.day,
                  timeslot: course.ay24.timeslot,
                };
              }

              return (
                <Box
                  key={index}
                  borderRadius="xl"
                  border="1px solid #D9D9D9"
                  backgroundColor="white"
                  w="full"
                >
                  <VStack px={20} py={10} gap={7}>
                    <VStack gap={0} alignItems="center">
                      <Text
                        fontSize="12"
                        fontWeight={700}
                        lineHeight="130%"
                        letterSpacing="1px"
                        color="#929292"
                        textTransform="uppercase"
                        mb={1}
                      >
                        Current (AY2024)
                      </Text>
                      <Text fontSize="16" lineHeight="140%" textAlign="center">
                        {course.ay24.level} {course.ay24.courseName}
                        <br />
                        {course.ay24.centre}
                        <br />
                        {course.ay24.day}, {course.ay24.timeslot}
                      </Text>
                    </VStack>
                    <ArrowDownIcon h="40px" w="40px" color="#013768" />
                    <VStack gap={0} alignItems="center">
                      <Text
                        fontSize="12"
                        fontWeight={700}
                        lineHeight="130%"
                        letterSpacing="1px"
                        color="#929292"
                        textTransform="uppercase"
                        mb={1}
                      >
                        Proposed (AY2025)
                      </Text>
                      <Text
                        fontSize="16"
                        lineHeight="140%"
                        fontWeight={600}
                        color="#013768"
                        textAlign="center"
                      >
                        {course.ay25.level} {course.ay25.courseName}
                        <br />
                        {course.ay25.centre}
                        <br />
                        {course.ay25.day}, {course.ay25.timeslot}
                      </Text>
                    </VStack>
                  </VStack>
                  <Divider />
                  <VStack p={8} alignItems={"flex-start"} textAlign="left">
                    <Text fontWeight={500}>
                      Does this time slot work for you?
                    </Text>
                    {renewError && (
                      <Text fontSize="14px" color="#C5341D" mt={0}>
                        {renewError.message}
                      </Text>
                    )}
                    <RadioGroup
                      mt={1}
                      onChange={() => {
                        resetField(`existingCourses.${index}.renewReason`);
                      }}
                    >
                      <Stack direction="column" textAlign={"left"}>
                        <Radio
                          {...register(`existingCourses.${index}.renew`)}
                          value="true"
                          gap={2}
                          fontWeight={400}
                          borderRadius={5}
                          _checked={{
                            backgroundColor: "#013768 !important",
                            borderColor: "#013768 !important",
                          }}
                        >
                          Most likely to work for us!
                        </Radio>
                        <Radio
                          {...register(`existingCourses.${index}.renew`)}
                          value="false"
                          gap={2}
                          textAlign={"left"}
                          fontWeight={400}
                          borderRadius={5}
                          _checked={{
                            backgroundColor: "#013768 !important",
                            borderColor: "#013768 !important",
                          }}
                        >
                          May be challenging.
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    {getValues().existingCourses[index].renew === "false" && (
                      <Box mt={7}>
                        <Text fontWeight={500}>
                          Please pick one of the reasons below:
                        </Text>
                        {renewReasonError && (
                          <Text fontSize="14px" color="#C5341D" mt={0}>
                            {renewReasonError.message}
                          </Text>
                        )}
                        <RadioGroup
                          textAlign={"left"}
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          mt={3}
                          gap={2}
                          defaultValue={
                            getValues().existingCourses[index].renewReason
                          }
                        >
                          <Radio
                            {...register(
                              `existingCourses.${index}.renewReason`
                            )}
                            variant="card-variant"
                            value="I'm not sure of my schedule yet"
                          >
                            I'm not sure of my schedule yet
                          </Radio>
                          <Radio
                            {...register(
                              `existingCourses.${index}.renewReason`
                            )}
                            variant="card-variant"
                            value="I'm waiting for the teacher allocation to be out"
                          >
                            I'm waiting for the teacher allocation to be out
                          </Radio>
                          <Radio
                            {...register(
                              `existingCourses.${index}.renewReason`
                            )}
                            variant="card-variant"
                            value="This centre's location is inconvenient for me"
                          >
                            This centre's location is inconvenient for me
                          </Radio>
                          <Radio
                            {...register(
                              `existingCourses.${index}.renewReason`
                            )}
                            variant="card-variant"
                            value="Others"
                          >
                            Others
                          </Radio>
                        </RadioGroup>
                      </Box>
                    )}
                  </VStack>
                </Box>
              );
            })}
          </VStack>
          <VStack mt={16} alignItems={"flex-start"}>
            <VStack textAlign={"left"} alignItems={"flex-start"}>
              <Text fontSize="18px" fontWeight={500} lineHeight="130%">
                Are there any other courses or time slots that you might be
                interested in? You can also propose other time slots for your
                child's current courses.
              </Text>
            </VStack>
            <VStack w="full" alignItems="stretch" mt={7} gap={5}>
              {_.isEmpty(fields) && (
                <Text fontStyle="italic" textAlign="center" mt={8}>
                  You have not added any courses yet.
                </Text>
              )}
              {fields.map((field, index) => {
                // using watch as a hack to trigger re-render
                const watchDay = watch(`newCourses.${index}.day`);

                const levelError = _.get(errors, `newCourses[${index}].level`);
                const courseNameError = _.get(
                  errors,
                  `newCourses[${index}].courseName`
                );
                const centreError = _.get(
                  errors,
                  `newCourses[${index}].centre`
                );
                const dayError = _.get(errors, `newCourses[${index}].day`);
                const timeslotError = _.get(
                  errors,
                  `newCourses[${index}].timeslot`
                );

                if (_.some(Object.values(field), (val) => _.isEmpty(val))) {
                  return (
                    <Box
                      key={field.id}
                      borderRadius="xl"
                      border="1px solid #D9D9D9"
                      backgroundColor="white"
                      p={8}
                    >
                      <VStack alignItems={"flex-start"} gap={5}>
                        <VStack alignItems={"flex-start"}>
                          <Text fontWeight={600}>Level</Text>
                          {levelError && (
                            <Text fontSize="14px" color="#C5341D" mt={0}>
                              {levelError.message}
                            </Text>
                          )}
                          <Select {...register(`newCourses.${index}.level`)}>
                            <option value="" disabled>
                              Select Level
                            </option>
                            {levels.map((level) => (
                              <option key={level} value={level}>
                                {level}
                              </option>
                            ))}
                          </Select>
                        </VStack>
                        <VStack alignItems={"flex-start"}>
                          <Text fontWeight={600}>Course</Text>
                          {courseNameError && (
                            <Text fontSize="14px" color="#C5341D" mt={0}>
                              {courseNameError.message}
                            </Text>
                          )}
                          <Select
                            {...register(`newCourses.${index}.courseName`)}
                          >
                            <option value="" disabled>
                              Select Course
                            </option>
                            {courseNames.map((course) => (
                              <option key={course} value={course}>
                                {course}
                              </option>
                            ))}
                          </Select>
                        </VStack>
                        <VStack alignItems={"flex-start"}>
                          <Text fontWeight={600}>Centre</Text>
                          {centreError && (
                            <Text fontSize="14px" color="#C5341D" mt={0}>
                              {centreError.message}
                            </Text>
                          )}
                          <Select {...register(`newCourses.${index}.centre`)}>
                            <option value="" disabled>
                              Select Centre
                            </option>
                            {centres.map((centre) => {
                              const hasInternalName =
                                centre in centreInternalToPublicNames;
                              return (
                                <option key={centre} value={centre}>
                                  {hasInternalName
                                    ? `${centreInternalToPublicNames[centre]}`
                                    : `${centre}`}
                                </option>
                              );
                            })}
                          </Select>
                        </VStack>
                        <VStack alignItems={"flex-start"}>
                          <Text fontWeight={600}>Day</Text>
                          {dayError && (
                            <Text fontSize="14px" color="#C5341D" mt={0}>
                              {dayError.message}
                            </Text>
                          )}
                          <Select {...register(`newCourses.${index}.day`)}>
                            <option value="" disabled>
                              Select Day
                            </option>
                            {days.map((day) => (
                              <option key={day} value={day}>
                                {day}
                              </option>
                            ))}
                          </Select>
                        </VStack>
                        <VStack alignItems={"flex-start"}>
                          <Text fontWeight={600}>Time</Text>
                          {timeslotError && (
                            <Text fontSize="14px" color="#C5341D" mt={0}>
                              {timeslotError.message}
                            </Text>
                          )}
                          <Select {...register(`newCourses.${index}.timeslot`)}>
                            <option value="" disabled>
                              Select Time Slot
                            </option>
                            {getValues().newCourses[index].day === "Saturday" ||
                            getValues().newCourses[index].day === "Sunday" ? (
                              <>
                                {weekendTimeslots.map((slot) => (
                                  <option key={slot} value={slot}>
                                    {slot}
                                  </option>
                                ))}
                              </>
                            ) : (
                              <>
                                {weekdayTimeslots.map((slot) => (
                                  <option key={slot} value={slot}>
                                    {slot}
                                  </option>
                                ))}
                              </>
                            )}
                          </Select>
                        </VStack>
                      </VStack>
                      <HStack mt={7} w="full" justifyContent="stretch">
                        <Button
                          variant="outline"
                          color="#013768"
                          borderColor={"#013768"}
                          disabled={isSubmitting}
                          onClick={() => remove(index)}
                          px={8}
                          py={3}
                          w="full"
                        >
                          Cancel
                        </Button>
                        <Button
                          colorScheme="blue"
                          backgroundColor="#013768"
                          _hover={{ backgroundColor: "#002B52" }}
                          onClick={() =>
                            update(index, getValues().newCourses[index])
                          }
                          disabled={isSubmitting}
                          px={8}
                          py={3}
                          w="full"
                        >
                          Save
                        </Button>
                      </HStack>
                    </Box>
                  );
                }
                return (
                  <FinalisedCourseCard
                    {...field}
                    removeCard={() => remove(index)}
                  />
                );
              })}
            </VStack>
            <Button
              variant="outline"
              onClick={() =>
                append({
                  level: "",
                  centre: "",
                  courseName: "",
                  day: "",
                  timeslot: "",
                })
              }
              color="#013768"
              borderColor="#013768"
              w="full"
              px={12}
              py={3}
              mt={6}
              leftIcon={<AddIcon />}
            >
              Add new timeslot
            </Button>
          </VStack>
          <VStack mt={20} alignItems={"flex-end"}>
            <Button
              colorScheme="blue"
              backgroundColor="#013768"
              _hover={{ backgroundColor: "#002B52" }}
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              px={12}
              py={3}
              w="full"
            >
              Submit
            </Button>
          </VStack>
        </form>
      </VStack>
    </VStack>
  );
};

export default CourseRenewalForm;
