import { Text, VStack } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

function SuccessPage() {
  return (
    <VStack
      backgroundColor="#F7F9FF"
      alignItems="center"
      px={12}
      pb={20}
      minHeight="100vh"
    >
      <VStack textAlign="center" mt={24} maxWidth="600px">
        <CheckCircleIcon color="#017C51" height="80px" width="80px" />
        <Text
          mt={3}
          w="80%"
          color="#017C51"
          fontSize="28px"
          fontWeight={800}
          lineHeight="120%"
        >
          Thank you for your response
        </Text>
        ;
        <Text mt={6} lineHeight="140%">
          We will carefully review your preferred time slots and keep you
          informed on any updates. If you have any further questions or
          inquiries, please don't hesitate to contact us via WhatsApp. We
          appreciate your patience and look forward to accomodating your
          schedule as best we can.
        </Text>
        ;
      </VStack>
    </VStack>
  );
}

export default SuccessPage;
