import axios, { isAxiosError } from "axios";

const BASE_API_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/v1";

export const ApiService = axios.create({
  baseURL: BASE_API_URL,
  timeout: 100_000, // 100 secs
  withCredentials: true,
});

export function isAuthenticationError(err: unknown) {
  return isAxiosError(err) && err.response?.status === 401;
}
