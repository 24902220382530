import { Box, HStack, IconButton, Text, VStack } from "@chakra-ui/react";

import { DeleteIcon } from "@chakra-ui/icons";

function FinalisedCourseCard({
  level,
  courseName,
  centre,
  day,
  timeslot,
  removeCard,
}: {
  level: string;
  courseName: string;
  centre: string;
  day: string;
  timeslot: string;
  removeCard: () => void;
}) {
  return (
    <Box
      borderRadius="xl"
      border="1px solid #D9D9D9"
      backgroundColor="white"
      p={7}
      w="full"
    >
      <HStack justifyContent={"space-between"}>
        <VStack
          gap={0}
          alignItems={"flex-start"}
          flexGrow={1}
          textAlign="left"
          fontWeight={600}
        >
          <Text>
            {level} {courseName}
          </Text>
          <Text>{centre}</Text>
          <Text>
            {day}, {timeslot}
          </Text>
        </VStack>
        <IconButton
          aria-label="Remove Course"
          icon={<DeleteIcon />}
          color="#C5341D"
          variant="ghost"
          onClick={removeCard}
        />
      </HStack>
    </Box>
  );
}

export default FinalisedCourseCard;
